import React from 'react';
import styled from 'styled-components';

import { Box } from '@core';
import { Text } from '@components';
import { ImageCore } from '@components/image';
import { query } from '@shared/style-helpers';

import redCross from '@images/red-cross.svg';
import checkMark from '@images/checkMark.svg';

const CellWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isFirstCol }) => (isFirstCol ? 'flex-start' : 'center')};

  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;

  @media ${query.maxTablet} {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  background-color: ${({ isHighlighted, isFirstRow, isEmpty, isOdd, theme }) => {
    if (isHighlighted) {
      if (isFirstRow) {
        return theme.colors.primary;
      } else if (isOdd) {
        return '#E9F1FF';
      } else {
        return '#F2F7FF';
      }
    } else if (isOdd) {
      return '#F3F4F6';
    } else if (!isEmpty) {
      return '#FFFFFF';
    }
  }};

  border-radius: ${({ isHighlighted, isFirstRow }) => isHighlighted && isFirstRow && '4px 4px 0 0'};
`;

const FeatureImage = styled(ImageCore)`
  opacity: ${({ isHighlighted }) => (isHighlighted ? 1 : 0.8)};
  width: ${({ isCheckMark }) => (isCheckMark ? '14px' : '10px')};
`;

const TableCell = ({ cellData, isFirstCol, isFirstRow, isOddRow, isHighlighted }) => {
  let cellContent;

  switch (cellData.type) {
    case 'label':
      cellContent = (
        <Text.Body3 fontSize={{ _: 12, lg: 14 }} lineHeight={{ _: '15px', lg: '20px' }}>
          {cellData.label}
        </Text.Body3>
      );
      break;
    case 'text':
      cellContent = (
        <Text.Body3Strong
          color={isHighlighted ? 'darkBlue' : 'darkBlue_80'}
          fontSize={{ _: 10, lg: 14 }}
          lineHeight={{ _: '14px', lg: '20px' }}
          whiteSpace="nowrap"
        >
          {cellData.label}
        </Text.Body3Strong>
      );
      break;
    case 'image':
      cellContent = (
        <Box display="flex" alignItems="center" height={{ _: 24, lg: 16 }}>
          <ImageCore src={cellData.image} alt={cellData.alt} maxHeight={16} />
        </Box>
      );
      break;
    case 'feature':
      cellContent = (
        <FeatureImage
          src={cellData.feature ? checkMark : redCross}
          alt={cellData.feature ? 'checkMark' : 'redCross'}
          isHighlighted={isHighlighted}
          isCheckMark={cellData.feature}
        />
      );
      break;
    default:
      cellContent = null;
  }

  return (
    <CellWrapper
      isFirstCol={isFirstCol}
      isFirstRow={isFirstRow}
      isOdd={isOddRow}
      isHighlighted={isHighlighted}
      isEmpty={cellData.type === 'empty'}
    >
      {cellContent}
    </CellWrapper>
  );
};

export default TableCell;
