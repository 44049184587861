import styled from 'styled-components';

import { query } from '@shared/style-helpers';

export default styled.div`
  display: grid;
  grid-template-columns: ${({ colAmount }) =>
    `minmax(min-content, max-content) repeat(${colAmount - 1}, auto)`};
  grid-template-rows: ${({ rowAmount }) => `repeat(${rowAmount}, auto)`};

  overflow-x: auto;
  justify-content: safe center;

  @media ${query.minTabletLarge} {
    padding-left: 12px;
    padding-right: 12px;
  }
`;
