import React from 'react';

import { TableContainer } from './table-container';
import { TableCell } from './table-cell';
import { TableHighlightLabel } from './table-highlight-label';

const ComparisonTable = ({ data, highlight }) => (
  <TableContainer colAmount={data[1].length} rowAmount={data.length}>
    {data.map((rowData, rowIndex) =>
      rowData.map((cell, colIndex) => {
        const isHighlighted = colIndex === highlight.col;

        return (
          <TableCell
            key={`${rowIndex}-${colIndex}`}
            cellData={cell}
            isOddRow={rowIndex % 2}
            isHighlighted={isHighlighted}
            isFirstRow={rowIndex === 0}
            isFirstCol={colIndex === 0}
          />
        );
      })
    )}

    {/*{highlight.col && highlight.text && (*/}
    {/*  <TableHighlightLabel colStart={highlight.col + 1} label={highlight.text} />*/}
    {/*)}*/}
  </TableContainer>
);

export default ComparisonTable;
