import React from 'react';
import styled from 'styled-components';

import { Text } from '@components';
import { query } from '@shared/style-helpers';

const HighlightLabel = styled.div`
  grid-column-start: ${({ colStart }) => colStart};
  height: 60px;

  @media ${query.maxTablet} {
    height: 48px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  background-color: #e9f1ff;
`;

const ComparisonHighlightLabel = ({ label, colStart }) => (
  <HighlightLabel colStart={colStart}>
    {typeof label === 'string' ? (
      <Text.Body3Strong color="white" textAlign="center" whiteSpace="nowrap">
        {label}
      </Text.Body3Strong>
    ) : (
      label
    )}
  </HighlightLabel>
);

export default ComparisonHighlightLabel;
